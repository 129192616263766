import React, { useEffect, useState } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import MUIBox from "../../Components/MUIBox";
import MUITypography from "../../Components/MUITypography";
import MUIGrid from "../../Components/MUIGrid";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import MUIButton from "../../Components/MUIButton";
import { Typography } from "@mui/material";

function Dashboard() {
  /**
   * State to hold the JSON data received from WebSocket
   */
  const [jsonData, setJsonData] = useState([]);
  const [refreshTimer, setRefreshTimer] = useState(null); // State to hold the timer
  const [time, setTime] = useState("");

  useEffect(() => {
    const client = new W3CWebSocket("ws://15.206.209.245:8003/ws");

    const fetchData = () => {
      client.send("get_data"); // Assuming you have a way to request data from the server
    };

    client.onopen = () => {
      console.log("WebSocket Client Connected");
      fetchData(); // Fetch data initially when WebSocket opens
    };

    client.onmessage = (message) => {
      try {
        const data = JSON.parse(message.data);
        setJsonData([...data.data]);
      } catch (error) {
        console.error("Error parsing message:", error);
      }
    };

    client.onclose = () => {
      console.log("WebSocket Connection Closed");
      clearInterval(refreshTimer); // Clear interval when WebSocket closes
    };

    // Setup timer to fetch data every 30 seconds
    const timer = setInterval(fetchData, 1000);
    setRefreshTimer(timer);

    // Cleanup function to close WebSocket connection and clear timer
    return () => {
      client.close();
      clearInterval(refreshTimer);
    };
  }, []); // Empty dependency array ensures this effect runs only once on component mount

  /**
   * time functionality for WebSocket
   */
  useEffect(() => {
    const updateTime = () => {
      const date = new Date();
      const options = {
        timeZone: "Asia/Kolkata",
        hour12: true,
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
      const indianTime = date.toLocaleTimeString("en-US", options);
      setTime(indianTime);
    };

    // Update time every second
    const intervalId = setInterval(updateTime, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <MUIBox p={2}>
      <MUIBox
        sx={{
          display: "flex",
          justifyContent: { xs: "center", md: "space-between" },
          alignItems: "center",
          flexWrap: "wrap",
          position: "relative",
          width: "100%",
          pb: 2,
        }}
      >
        <MUITypography
          variant="h4"
          pb={1}
          sx={{
            flexGrow: { xs: 0, md: 1 },
            textAlign: "center",
            width: { xs: "100%", md: "auto" },
          }}
        >
          Forex Strategies
        </MUITypography>
        <MUIBox
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            position: { xs: "static", md: "absolute" },
            right: { md: 0 },
            justifyContent: { xs: "center", md: "flex-end" },
            width: { xs: "100%", md: "auto" },
          }}
        >
          <MUITypography variant="h6">{time}</MUITypography>
          <MUIButton
            title="High"
            variant="contained"
            sx={{
              backgroundColor: "success.main",
              "&:hover": {
                backgroundColor: "success.main",
              },
            }}
          />
          <MUIButton
            title="Low"
            variant="contained"
            sx={{
              backgroundColor: "error.main",
              "&:hover": {
                backgroundColor: "error.main",
              },
            }}
          />
        </MUIBox>
      </MUIBox>

      <MUIBox p={3} sx={{ border: "1px solid gray", borderRadius: "10px" }}>
        {jsonData.length === 0 ? (
          <MUITypography
            variant="h5"
            sx={{
              textAlign: "center",
              color: "#FF0000",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ErrorOutlineIcon sx={{ marginRight: 1 }} /> No strategies found
          </MUITypography>
        ) : (
          <MUIGrid container spacing={3}>
            {jsonData.map((item, index) => (
              <MUIGrid key={item.id || index} item xs={12} md={4}>
                <MUIBox sx={{ border: "1px solid gray", borderRadius: "5px" }}>
                  <MUIBox p={2}>
                    <MUIGrid container spacing={2}>
                      <MUIGrid item xs={12} sx={{ borderRadius: "10px" }}>
                        <MUITypography
                          pb={1}
                          color="warning.main"
                          fontWeight="bold"
                          variant="h6"
                        >
                          {item?.symbol}
                        </MUITypography>
                        {item?.timeframe?.map((timeframeItem, tfIndex) => (
                          <MUIBox key={tfIndex} sx={{ pb: 2 }}>
                            <MUIBox
                              bgcolor={
                                timeframeItem?.status === "high"
                                  ? "success.main"
                                  : timeframeItem?.status === "low"
                                  ? "error.main"
                                  : timeframeItem?.status === "red"
                                  ? "error.main"
                                  : timeframeItem?.status === "green"
                                  ? "success.main"
                                  : "gray"
                              }
                              color="white"
                              fontWeight="bold"
                              sx={{
                                borderRadius: "5px",
                                textTransform: "uppercase",
                              }}
                            >
                              <MUIBox p={1}>
                                {timeframeItem?.minute} |{" "}
                                {timeframeItem?.status}
                              </MUIBox>
                              <MUIBox p={1}>
                                <Typography variant="h6">
                                  {timeframeItem?.time}
                                </Typography>
                              </MUIBox>
                            </MUIBox>
                          </MUIBox>
                        ))}
                      </MUIGrid>
                    </MUIGrid>
                  </MUIBox>
                </MUIBox>
              </MUIGrid>
            ))}
          </MUIGrid>
        )}
      </MUIBox>
    </MUIBox>
  );
}

export default Dashboard;
