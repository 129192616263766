import React from "react";
import { Typography } from "@mui/material";

function MUITypography(props) {
  /**
   * props
   */
  const { children, ...rest } = props;

  return <Typography {...rest}>{children}</Typography>;
}

export default MUITypography;
