import { Button } from "@mui/material";
import React from "react";

function MUIButton(props) {
  /**
   * props
   */
  const {
    title,
    fullWidth,
    type,
    onClick,
    sx,
    variant,
    startIcon,
    disabled,
    size,
    endIcon,
    color,
    icon,
  } = props;

  return (
    <>
      <Button
        type={type}
        fullWidth={fullWidth}
        onClick={onClick}
        sx={sx}
        size={size}
        variant={variant}
        color={color}
        startIcon={startIcon}
        disabled={disabled}
        endIcon={endIcon}
        icon={icon}
      >
        {title}
      </Button>
    </>
  );
}

export default MUIButton;
