import React from "react";
import { Grid } from "@mui/material";

function MUIGrid(props) {
  /**
   * props
   */
  const { children, ...rest } = props;

  return <Grid {...rest}>{children}</Grid>;
}

export default MUIGrid;
